import React from 'react'
import Router from './routes'
import { store, ThriveApplication } from '@thriveglobal/thrive-web-core'
import { Provider } from 'react-redux'

export default function Root(props) {
    return (
        <ThriveApplication
            apolloClientOptions={{
                cacheOptions: {},
                name: 'thrive-web-dashboard'
            }}
            sentryDsn={process.env.SENTRY_DSN}
        >
            <Provider store={store}>
                <section>
                    <Router />
                </section>
            </Provider>
        </ThriveApplication>
    )
}

// Global __THRIVE__ object
declare global {
    interface Window {
        __THRIVE__: any
    }
}
;(window.__THRIVE__ = window.__THRIVE__ || []).push({
    app: process.env.APP_NAME,
    version: process.env.APP_VERSION,
    created: process.env.CREATED_AT
})
