import { useEffect } from 'react'
import { store } from '@thriveglobal/thrive-web-core'
import {
    auth,
    AuthType,
    selectIsAccessTokenExpired,
    selectIsRefreshTokenExpired,
    selectIsAccessTokenExpiringSoon
} from '@thriveglobal/thrive-web-auth-core'

async function startAuth(
    isTokenExpired: boolean,
    isTokenExpiringSoon: boolean,
    isRefreshTokenExpired: boolean
) {
    auth.setAuthType(AuthType.identity_internal)
    try {
        // Refresh if token is expired/soon
        if ((isTokenExpired || isTokenExpiringSoon) && !isRefreshTokenExpired) {
            await auth.refreshToken()
        } else if (
            (isTokenExpired || isTokenExpiringSoon) &&
            isRefreshTokenExpired
        ) {
            await auth.signIn()
        }
    } catch {
        auth.signOut()
    }
}

function useAdminAuth() {
    const authState = store.getState().auth
    useEffect(() => {
        const isTokenExpired = selectIsAccessTokenExpired(authState)
        const isTokenExpiringSoon = selectIsAccessTokenExpiringSoon(authState)
        const isRefreshTokenExpired = selectIsRefreshTokenExpired(authState)

        // Ignore auth on login callback route, handled elsewhere
        if (!window.location.pathname.includes('login/callback')) {
            startAuth(
                isTokenExpired,
                isTokenExpiringSoon,
                isRefreshTokenExpired
            )
        }
    }, [authState])

    useEffect(() => {
        const intervalId = setInterval(() => {
            const isTokenExpired = selectIsAccessTokenExpired(authState)
            const isTokenExpiringSoon =
                selectIsAccessTokenExpiringSoon(authState)
            const isRefreshTokenExpired = selectIsRefreshTokenExpired(authState)

            if (isTokenExpired || isTokenExpiringSoon) {
                startAuth(
                    isTokenExpired,
                    isTokenExpiringSoon,
                    isRefreshTokenExpired
                )
            }
        }, 60 * 1000)

        return () => clearInterval(intervalId)
    }, [authState])
}

export default useAdminAuth
