import { useEffect } from 'react'
import { store } from '@thriveglobal/thrive-web-core'
import {
    auth,
    AuthType,
    selectIsAccessTokenExpired,
    selectIsRefreshTokenExpired,
    selectIsAccessTokenExpiringSoon
} from '@thriveglobal/thrive-web-auth-core'

function useAdminAuth() {
    const authState = store.getState().auth
    const isTokenExpired = selectIsAccessTokenExpired(authState)
    const isTokenExpiringSoon = selectIsAccessTokenExpiringSoon(authState)
    const isRefreshTokenExpired = selectIsRefreshTokenExpired(authState)
    useEffect(() => {
        const startAuth = async () => {
            auth.setAuthType(AuthType.identity_internal)
            try {
                // Refresh if token is expired/soon
                if (
                    (isTokenExpired || isTokenExpiringSoon) &&
                    !isRefreshTokenExpired
                ) {
                    await auth.refreshToken()
                } else if (
                    (isTokenExpired || isTokenExpiringSoon) &&
                    isRefreshTokenExpired
                ) {
                    await auth.signIn()
                }
            } catch {
                auth.signOut()
            }
        }
        // Ignore auth on login callback route, handled elsewhere
        if (!window.location.pathname.includes('login/callback')) {
            startAuth()
        }
    }, [isTokenExpired, isTokenExpiringSoon, isRefreshTokenExpired])
}

export default useAdminAuth
