import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { KeyCloakProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import useAdminAuth from '../hooks/useAdminAuth'

const DefaultPage = lazy(() => import('../pages/Default'))

export default function Router() {
    const theme = useTheme()
    useAdminAuth()
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <Suspense fallback={<></>}>
                    <Switch>
                        <KeyCloakProtectedRoute component={DefaultPage} />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}
